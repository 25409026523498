import styled from "styled-components";
// import BgRodape from "../../Images/BgRodape.jpg";

export const OutPartners = styled.section`
  padding-top: 85px;

  @media (max-width: 900px) {
    padding-top: 50px;
  }
`;

export const TopPartners = styled.div`
  display: flex;
  justify-content: center;
`;

export const OutRisk = styled.div``;

export const TextPartners1 = styled.div`
  font-size: 40px;
  font-weight: 500;
  text-transform: uppercase;
  padding-left: 20px;
  margin-top: -7px;
  line-height: 45px;
  color: #000;
`;

export const ContentPartners = styled.div`
  width: 1400px;
  margin: 0 auto;

  @media (max-width: 1450px) {
    width: 1130px;
  }

  @media (max-width: 1150px) {
    width: 850px;
  }

  @media (max-width: 900px) {
    width: 90%;
  }
`;

export const OutAgency = styled.div`
  margin-top: 55px;

  @media (max-width: 900px) {
    margin-top: 40px;
  }
`;

export const TextPartners2 = styled.div`
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 15px;
  margin-top: 40px;
  text-align: center;
`;

export const ItensPartners = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  flex-wrap: wrap;
`;

export const ItemPartner = styled.div`
  font-size: 18px;
  margin-top: 15px;
  width: 18%;
  text-align: center;

  @media (max-width: 900px) {
    width: 31%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 600px) {
    width: 47%;
    font-size: 17px;
    margin-top: 5px;
  }

  @media (max-width: 450px) {
    width: 100%;
    font-size: 18px;
    margin-top: 10px;
  }
`;

export const BgFooter = styled.footer`
  display: flex;
  justify-content: flex-end;
  padding-top: 140px;

  @media (max-width: 1140px) {
    padding-top: 55px;
  }

  @media (max-width: 470px) {
    height: 165px;
  }
`;

export const OutFooter = styled.div`
  width: 90%;
  display: flex;

  @media (max-width: 1140px) {
    width: 95%;
  }

  @media (max-width: 540px) {
    width: 100%;
  }
`;

export const OutRiskFooter = styled.div`
  @media (max-width: 1600px) {
    width: 45px;
    margin-bottom: -5px;
  }

  @media (max-width: 470px) {
    display: none;
  }
`;

export const RightFooter = styled.div`
  display: flex;
  width: 100%;
  background-color: #000;
`;

export const ContentFooter = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1020px;
  align-items: center;
  padding-left: 60px;

  @media (max-width: 1300px) {
    width: 890px;
  }

  @media (max-width: 1140px) {
    padding-left: 20px;
  }

  @media (max-width: 1030px) {
    width: 800px;
  }

  @media (max-width: 930px) {
    width: 555px;
    flex-wrap: wrap;
  }

  @media (max-width: 680px) {
    width: 90%;
  }
`;

export const LogoFooter = styled.div`
  @media (max-width: 460px) {
    width: 99px;
  }
`;

export const TextTel = styled.div`
  color: #fff;
  font-weight: 500;
  font-size: 22px;

  span {
    font-weight: 400;
  }

  @media (max-width: 1030px) {
    font-size: 20px;
  }

  @media (max-width: 460px) {
    font-size: 17px;
  }
`;

export const OutIcons = styled.div`
  display: flex;
  width: 175px;
  justify-content: space-between;

  @media (max-width: 1030px) {
    width: 145px;
  }

  @media (max-width: 930px) {
    width: 182px;
  }

  @media (max-width: 500px) {
    width: 140px;
  }
`;

export const Icon = styled.div`
  cursor: pointer;
`;

export const OutButtonFooter = styled.div`
  cursor: pointer;

  @media (max-width: 1030px) {
    width: 220px;
  }

  @media (max-width: 460px) {
    width: 179px;
  }
`;
