import styled from "styled-components";
import ImgBgHistoric from "../../Images/BgHistoric.jpg";

export const BgHistoric = styled.section`
  background-color: #e1e1e1;
`;

export const OutUpHistoric = styled.div`
  width: 1350px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding-top: 150px;

  @media (max-width: 1400px) {
    width: 1110px;
    padding-top: 85px;
  }  
  
  @media (max-width: 1140px) {
    width: 90%;
  }  
  
  @media (max-width: 800px) {
    flex-wrap: wrap;
  }
`;

export const LeftUpHistoric = styled.div`
  width: 56%;
  margin-top: 25px;

  @media (max-width: 1400px) {
    margin-top: 0px;
  }

  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const OutTextHistoric1 = styled.div`
  display: flex;
  align-items: center;
`;

export const RiscoHistoric = styled.div``;

export const TextHistoric1 = styled.div`
  font-weight: 600;
  font-size: 40px;
  color: #000000;
  text-transform: uppercase;
  margin-top: -7px;
  margin-left: 30px;
`;

export const ContentTextHistoric = styled.div`
  width: 92%;
  float: right;
`;

export const TextHistoric2 = styled.div`
  font-weight: 500;
  font-size: 25px;
  color: #000000;
`;

export const TextHistoric3 = styled.div`
  font-weight: 400;
  font-size: 20px;
  color: #000000;
  margin-top: 22px;
`;

export const OutImgButtonHistoric = styled.div`
  width: 252px;
  margin-top: 35px;
  cursor: pointer;
`;

export const RightHistoric = styled.div`
  width: 40%;

  @media (max-width: 800px) {
    width: 400px;
    margin: 0 auto;
    margin-top: 30px;
  }  
  
  @media (max-width: 420px) {
    width: 90%;
  }
`;

export const OutDownHistoric = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 0px;
  margin-top: 35px;

  @media (max-width: 1140px) {
    justify-content: center;
    padding-bottom: 80px;
  }
`;

export const OutDownHistoric2 = styled.div`
  width: 1550px;

  @media (max-width: 1600px) {
    width: 1375px;
  }

  @media (max-width: 1400px) {
    width: 95%;
  }
`;

export const OutContentHistoric = styled.div`
  display: flex;
  align-items: center;
  width: 67%;
  justify-content: space-between;
  margin: 0 auto;

  @media (max-width: 1400px) {
    width: 82%;
  }  
  
  @media (max-width: 1140px) {
    width: 90%;
    flex-wrap: wrap;
  }
`;

export const ItemHistoric = styled.div``;

export const OutImgHistoric = styled.div``;

export const OutTextItemHistoric = styled.div`
  display: flex;
`;

export const ImgRiscoHistoric = styled.div`
  width: 15px;
  margin-top: 4px;
`;

export const TextItemHistoric = styled.div`
  font-weight: 500;
  font-size: 16px;
  padding-left: 10px;
  padding-top: 2px;
  width: 90%;
`;

export const OutRiskDownHistoric = styled.div`
  margin-top: -237px;
  padding-bottom: 130px;

  @media (max-width: 1400px) {
    margin-top: -16vw;
    padding-bottom: 75px;
  }  
  
  @media (max-width: 1140px) {
    display: none;
  }
`;
