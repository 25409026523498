/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import * as S from "./estilos";

import ImgRiscoItem from "../../Images/RiscoItem.png";
import ImgIconDownload from "../../Images/IconDownload.png";
import Img50Anos from "../../Images/Img50Anos.png";
import ImgItemHistoric1 from "../../Images/ImgItemHistoric1.png";
import ImgItemHistoric2 from "../../Images/ImgItemHistoric2.png";
import ImgItemHistoric3 from "../../Images/ImgItemHistoric3.jpg";
import ImgRiscoHistoric from "../../Images/ImgRiscoHistoric.png";
import ImgButtonHistoric from "../../Images/ButtonHistoric.png";

function Historic(props: any) {
  const MandaPdf = () => {
    window.open("https://publistand.com.br/Apresentaçao_Publistand_2023_50A.pdf", "_blank");
  };

  return (
    <>
      <div id="Historic"></div>
      <S.BgHistoric>
        <S.OutUpHistoric>
          <S.LeftUpHistoric>
            <S.OutTextHistoric1>
              <S.RiscoHistoric>
                <img src={ImgRiscoItem} />
              </S.RiscoHistoric>
              <S.TextHistoric1>Histórico</S.TextHistoric1>
            </S.OutTextHistoric1>
            <S.ContentTextHistoric>
              <S.TextHistoric2>
                Com mais de 50 anos de atuação, a Publistand é uma das empresas líderes do mercado de eventos no país.
              </S.TextHistoric2>
              <S.TextHistoric3>
                Desde 1973 crescer com competência e profissionalismo tem sido o principal desafio da Publistand, nesses
                anos dedicados ao setor de criação, desenvolvimento e montagem de estandes em exposições; cenografia e
                organização de eventos corporativos, temáticos e internacionais; instalações para show room; eventos
                para lançamento de produtos e displays.
              </S.TextHistoric3>
              <S.TextHistoric3>
                Representada pela sua fundadora e presidente, Ana Maria de Oliveira, a Publistand conquistou essa
                posição de destaque no mercado expositor brasileiro, devido ao constante investimento na formação e
                desenvolvimento de uma equipe altamente qualificada, capaz de absorver as inovações da indústria e
                oferecer soluções adequadas aos clientes em diversos eventos.
              </S.TextHistoric3>
              <S.OutImgButtonHistoric onClick={() => MandaPdf()}>
                <img src={ImgButtonHistoric} />
              </S.OutImgButtonHistoric>
            </S.ContentTextHistoric>
          </S.LeftUpHistoric>
          <S.RightHistoric>
            <img src={Img50Anos} />
          </S.RightHistoric>
        </S.OutUpHistoric>

        <S.OutDownHistoric>
          <S.OutDownHistoric2>
            <S.OutContentHistoric>
              <S.ItemHistoric className="ItemHistoric1">
                <S.OutImgHistoric>
                  <img src={ImgItemHistoric1} />
                </S.OutImgHistoric>
                <S.OutTextItemHistoric>
                  <S.ImgRiscoHistoric>
                    <img src={ImgRiscoItem} />
                  </S.ImgRiscoHistoric>
                  <S.TextItemHistoric>32 Vezes Campeã do “Prêmio Caio”</S.TextItemHistoric>
                </S.OutTextItemHistoric>
              </S.ItemHistoric>
              <S.ItemHistoric className="ItemHistoric2">
                <S.OutImgHistoric>
                  <img src={ImgItemHistoric2} />
                </S.OutImgHistoric>
                <S.OutTextItemHistoric>
                  <S.ImgRiscoHistoric>
                    <img src={ImgRiscoItem} />
                  </S.ImgRiscoHistoric>
                  <S.TextItemHistoric>Sede São Paulo 2.200m² de Área Construída</S.TextItemHistoric>
                </S.OutTextItemHistoric>
              </S.ItemHistoric>
              <S.ItemHistoric className="ItemHistoric3">
                <S.OutImgHistoric>
                  <img src={ImgItemHistoric3} />
                </S.OutImgHistoric>
                <S.OutTextItemHistoric>
                  <S.ImgRiscoHistoric>
                    <img src={ImgRiscoItem} />
                  </S.ImgRiscoHistoric>
                  <S.TextItemHistoric>Equipe Publistand</S.TextItemHistoric>
                </S.OutTextItemHistoric>
              </S.ItemHistoric>
            </S.OutContentHistoric>
            <S.OutRiskDownHistoric>
              <img src={ImgRiscoHistoric} />
            </S.OutRiskDownHistoric>
          </S.OutDownHistoric2>
        </S.OutDownHistoric>
      </S.BgHistoric>
    </>
  );
}

export default withRouter(Historic);
