/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import * as S from "./estilos";

import ImgArquitertura from "../../Images/ImgArquitertura.png";
import RiscoMenu2 from "../../Images/RiscoMenu2.png";

function Architecture(props: any) {
  return (
    <>
    <div id="Arquitetura"></div>
      <S.BgArchitecture >
        <S.ContentArchitecture>
          <S.LeftArchitecture>
            <S.TopArchitecture>
              <S.OutRisk>
                <img src={RiscoMenu2} />
              </S.OutRisk>
              <S.TextArchitecture1>Arquitetura & Organização</S.TextArchitecture1>
            </S.TopArchitecture>
            <S.OutTextArchitecture>
              <S.TextArchitecture2>
                Soluções inteligentes e funcionais exigem muito mais do que competência e profissionalismo. Dependem do
                bom relacionamento entre quem contrata, quem comanda e quem executa o trabalho. A filosofia de trabalho da
                Publistand é baseada no respeito ao cliente, na valorização do potencial humano e na capacidade de
                estabelecer um comportamento ético em todas as etapas da negociação. E é por isso que a Publistand, ao
                longo dos anos, estabeleceu importantes parcerias internacionais, se associando a diversas empresas da
                Europa, Ásia e Estados Unidos, além das parcerias no Brasil e países da América do Sul.
              </S.TextArchitecture2>

              <S.TextArchitecture2 style={{marginTop: "20px"}}>
                Através de um serviço personalizado em todas as etapas do desenvolvimento do projeto, os profissionais da
                Publistand podem captar as reais necessidades dos clientes, criando verdadeiras sedes virtuais que
                alavancará os produtos, serviços e principalmente o que cada expositor tem de mais importante: a marca da
                sua empresa. Uma equipe de arquitetos, designers e decoradores estudam as necessidades específicas de cada
                produto, permitindo que as soluções possam ser visualizadas em softwares CAD e maquetes.
              </S.TextArchitecture2>
            </S.OutTextArchitecture>

            {/* 
            <S.TextArchitecture2>
              Através de um serviço personalizado em todas as etapas do desenvolvimento do projeto, os profissionais da
              Publistand podem captar as reais necessidades dos clientes, criando verdadeiras sedes virtuais que
              alavancará os produtos, serviços e principalmente o que cada expositor tem de mais importante: a marca da
              sua empresa. Uma equipe de arquitetos, designers e decoradores estudam as necessidades específicas de cada
              produto, permitindo que as soluções possam ser visualizadas em softwares CAD e maquetes.
            </S.TextArchitecture2>
            */}
          </S.LeftArchitecture>
          <S.RightArchitecture>
            <img src={ImgArquitertura} />
          </S.RightArchitecture>
        </S.ContentArchitecture>
      </S.BgArchitecture>
    </>
  );
}

export default withRouter(Architecture);
