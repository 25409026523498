import styled from "styled-components";
import ImgBgBranco from "../../Images/ImgBgBranco.png";
import ImgBgCases from "../../Images/BgCases.jpg";

export const OutCases = styled.section`
  margin-top: -20px;

  @media (max-width: 950px) {
    margin-top: 20px;
  }
`;

export const ItemCases = styled.div`

`;

export const LeftCases = styled.div`
  display: flex;
  background-image: url(${ImgBgBranco});
  background-repeat: no-repeat;
  width: 57%;
  background-size: contain;
  height: 33vw;
  position: relative;
  z-index: 2;
  align-items: center;

  @media (max-width: 1170px) {
    background-image: unset;
    width: 81%;
    height: auto;
    margin: 0 auto;
    margin-top: 45px;
  }
  
  @media (max-width: 520px) {
    width: 75%;
  }
`;

export const ContentLeftCases = styled.div`
  width: 60%;
  margin: 0 auto;

  @media (max-width: 1170px) {
    width: 100%;
  }
`;

export const TopLeftCases = styled.div`
  display: flex;
  align-items: center;
`;

export const OutImgRisk = styled.div``;

export const TextCases1 = styled.div`
  font-weight: 600;
  font-size: 40px;
  text-transform: uppercase;
  color: #000;
  padding-left: 30px;
`;

export const MidCases = styled.div`
  padding-top: 30px;

  @media (max-width: 1310px) {
    padding-top: 10px;
  }
`;

export const TextCases2 = styled.div`
  font-weight: 500;
  font-size: 25px;
  color: #fff;
  text-transform: uppercase;
  background-color: #000;
  width: auto;
  text-align: center;

  @media (max-width: 1170px) {
    font-size: 20px;
    align-items: center;
    justify-content: center;
  }  
  
  @media (max-width: 520px) {
    font-size: 15px;
    display: flex;
  }
`;

export const TextCases3 = styled.div`
  font-weight: 500;
  font-size: 25px;
  color: #000;
  margin-top: 18px;
  margin-bottom: 18px;

  span {
    color: #767676;
  }

  @media (max-width: 1310px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

export const TextCases4 = styled.div`
  font-weight: 400;
  font-size: 20px;
  height: 265px;
  overflow: hidden;
  overflow-y: scroll;
  padding-right: 30px;
  color: #000;

  @media (max-width: 1550px) {
    height: 150px;
  }  
  
  @media (max-width: 1310px) {
    font-size: 18px;
  }

  @media (max-width: 950px) {
    margin-bottom: 25px;
  }
`;

export const OutImgCases = styled.div`

`;

export const RightCases = styled.div`
  background-image: url(${ImgBgCases});
  background-repeat: no-repeat;
  background-size: cover;
  height: 39vw;
  position: relative;
  z-index: 1;
  margin-top: -33vw;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 1170px) {
    margin-top: 7vw;
    position: unset;
    height: 43vw;
  }
`;

export const ImageOpacity = styled.div`
    width: 24.4%;
    float: right;

    @media (max-width: 1170px) {
      width: 26.9%;
  }
`;

export const RightCasesResp = styled.div`
  background-image: url(${ImgBgCases});
  background-repeat: no-repeat;
  background-size: cover;
  height: 39vw;
  position: relative;
  z-index: 1;
  margin-top: -33vw;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  display: none;

  @media (max-width: 1170px) {
    margin-top: -38vw;
  }

  @media (max-width: 950px) {
    background-image: unset;
    height: auto;
    margin-top: unset;
    display: none;
  }
`;

export const OutIconsCases = styled.div`
    padding-right: 17vw;
    padding-top: 25px;
    position: fixed;

    @media (max-width: 1170px) {
      padding-right: 39vw;
      padding-top: 0px;
      position: fixed;
      margin-top: -43px;
    }
`;

export const IconCases = styled.div`
  width: 125px;
  margin-top: 20px;
  cursor: pointer;

  @media (max-width: 1170px) {
    display: block;
    width: 80px;
    margin-top: 0px;
    cursor: pointer;
  }
`;

export const IconCasesResp1 = styled.div`
  display: none;
  width: 100px;
  position: absolute;
  margin-top: -51vw;
  margin-left: 34vw;
  cursor: pointer;

  @media (max-width: 950px) {
    display: block;
  }

  @media (max-width: 520px) {
    width: 85px;
    position: absolute;
    margin-left: 29vw;
  }
`;

export const IconCasesResp2 = styled.div`
  display: none;
  width: 100px;
  position: absolute;
  margin-top: -37vw;
  margin-left: 34vw;
  cursor: pointer;

  @media (max-width: 950px) {
    display: block;
  }

  @media (max-width: 650px) {
    margin-top: -32vw;
  }  
  
  @media (max-width: 520px) {
    width: 85px;
    position: absolute;
    margin-left: 29vw;
    margin-top: -29vw;
  }
`;


export const BgImageCases = styled.div`
    width: 100%;
    height: 100%;
    background-color: #000;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 1000;
    display: flex;
    align-items: center;
`;


export const TextXCases = styled.div`
    color: #fff;
    position: absolute;
    top: 15px;
    right: 30px;
    font-weight: bold;
    font-size: 26px;
    cursor: pointer;
`;

export const OutImageCases = styled.div`

`;
