/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import * as S from './estilos';

import { Alert } from '../atoms';

import RiscoItem from '../../Images/RiscoItem.png';
import ImgCinza1 from '../../Images/ImgCinza1.png';
import ImgContact from '../../Images/ImgContact.png';
import ImgContact2 from '../../Images/ImgContact2.png';
// import ImgContact2Resp from '../../Images/ImgContact2Resp.png';
import ImgContact2Resp from '../../Images/ImgContact2Resp.jpg';

function Contact(props: any) {
  const [load, setLoad] = useState(false);
  const [nome, setNome] = useState('');
  const [empresa, setEmpresa] = useState('');
  const [email, setEmail] = useState('');
  const [celular, setCelular] = useState('');
  const [mensagem, setMensagem] = useState('');


  const phoneMask = (value: string) => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{5})(\d{4})/, '$1-$2');
  };

  const clearFields = () => {
    setNome('');
    setCelular('');
    setEmail('');
    setMensagem('');
  };


  const onSubmit = () => {
    let validaMenssages = [];
    validaMenssages.push(<div className='TextMensagem' style={{ marginBottom: '10px' }}>Atenção, segue abaixo campos obrigatórios:</div>);
    let valida = false;
    if (nome === '' || nome === undefined) {
      validaMenssages.push(<div className='TextMensagem2' key={'Nome'}>- Nome</div>);
      valida = true;
    }
    if (empresa === '' || empresa === undefined) {
      validaMenssages.push(<div className='TextMensagem2' key={'Empresa'}>- Empresa</div>);
      valida = true;
    }    
    
    if (celular === '' || celular === undefined) {
      validaMenssages.push(<div className='TextMensagem2' key={'Telefone'}>- Telefone</div>);
      valida = true;
    }
    // alert(captcha);
    // if (captcha === '' || captcha === undefined) {
    //   validaMenssages.push(<div key={'Telefone'}>- Verificação de robô</div>);
    //   valida = true;
    // }
    if (email === '' || email === undefined) {
      validaMenssages.push(<div className='TextMensagem2' key={'E-mail'}>- E-mail</div>);
      valida = true;
    } else {
      if (
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          email,
        )
      ) {
        null;
      } else {
        validaMenssages.push(<div className='TextMensagem2' key={'E-mail'}>- E-mail (inválido)</div>);
        valida = true;
      }
    }
    if (mensagem === '' || mensagem === undefined) {
      validaMenssages.push(<div className='TextMensagem2' key={'Mensagem'}>- Mensagem</div>);
      valida = true;
    }
    if (valida) {
      Alert(<div>{validaMenssages}</div>);
    } else {
      setLoad(true);

      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      
      const raw = JSON.stringify({
        'Nome': nome,
        'Celular': celular,
        'Empresa': empresa,
        'Email': email,
        'Mensagem': mensagem
      });

      validaMenssages = [];
         
      fetch('https://www.publistand.com.br/app_site/envia_contato.php', {method: 'POST', headers: myHeaders, body: raw, redirect: 'follow'})
        .then(response => response.text())
        .then((response: any) => {
          // console.log(response);
          setLoad(false);
          Alert(
            <>
              <div className='TextMensagem3'>Formulário de contato enviado com sucesso!</div>
              <div className='TextMensagem4'>Em breve entraremos em contato.</div>
            </>,
          );
          clearFields();
          validaMenssages = [];
          // setNome('');
          // setEmail('');
          // setCelular('');
          // setMensagem('');
          setLoad(false);
        })
        .catch(error => console.log('error', error));
    }
  };

  return (
    <>
      <div id='Contact'></div>
      <S.BgContact>
        <S.OutContact>
          <S.TopContact>
            <S.LeftTopContact>
              <S.RiskContact><img src={RiscoItem} /></S.RiskContact>
              <S.TextContact1>Contato</S.TextContact1>
            </S.LeftTopContact>
            <S.RightTopContact>
              <S.OutImgCinza><img src={ImgCinza1} /></S.OutImgCinza>
              <S.BgRightContact />
            </S.RightTopContact>
          </S.TopContact>
          <S.ContainerContact>
            <S.LeftContainer>
              <S.OutImgLeftContainer><img src={ImgContact} /></S.OutImgLeftContainer>
            </S.LeftContainer>            
            <S.RightContainer>
            <form className='FormContato'>
              <input
                className='InputForm'
                type='text'
                onChange={(e) => setNome(e.target.value)}
                value={nome}
                name='c_nome'
                id='c_nome'
                placeholder='Nome'
              />
              <input
                className='InputForm'
                onChange={(e) => setEmpresa(e.target.value)}
                value={empresa}
                name='c_empresa'
                id='c_empresa'
                placeholder='Empresa'
              />              
              <input
                className='InputForm'
                onChange={(e) => setCelular(e.target.value)}
                value={phoneMask(celular)}
                maxLength={15}
                name='c_telefone'
                id='c_telefone'
                placeholder='Telefone'
              />
              <input
                className='InputForm'
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                name='c_email'
                id='c_email'
                placeholder='E-mail'
              />
              <textarea
                className='InputTextArea'
                onChange={(e) => setMensagem(e.target.value)}
                value={mensagem}
                name='c_mensagem'
                id='c_mensagem'
                placeholder='Mensagem'
              />
              <S.ButtonForm onClick={() => onSubmit()} type='button'>Enviar</S.ButtonForm>
            </form>
            </S.RightContainer>
          </S.ContainerContact>
          <S.OutImgContact2><img src={ImgContact2} /></S.OutImgContact2>
          <S.OutImgContact2Resp><img src={ImgContact2Resp} /></S.OutImgContact2Resp>
        </S.OutContact>
      </S.BgContact>
    </>
  );
}

export default withRouter(Contact);
