import styled from "styled-components";
import BgArquitetura from "../../Images/BgArquitetura.jpg";
import BgArquiteturaResp from "../../Images/BgArquiteturaResp.jpg";

export const BgArchitecture = styled.section`
  margin-top: -20px;
  background-image: url(${BgArquitetura});
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 130px;

  @media (max-width: 1350px) {
    padding-bottom: 45px;
  }  
  
  @media (max-width: 950px) {
    background-image: url(${BgArquiteturaResp})
  }
  
`;

export const ContentArchitecture = styled.div`
  display: flex;
  /* width: 1670px; */
  width: 87%;
  justify-content: space-between;
  align-items: center;
  padding-top: 13vw;

  @media (max-width: 1350px) {
    width: 93%;
  }

  @media (max-width: 950px) {
    flex-direction: column;
    width: 85%;
    margin: 0 auto;
  }
`;

export const LeftArchitecture = styled.div`
  width: 31%;

  @media (max-width: 1350px) {
    width: 37%;
  }

  @media (max-width: 950px) {
    width: 100%;
  }
`;

export const TopArchitecture = styled.div`
  display: flex;
`;

export const OutRisk = styled.div`
  width: 26px;
`;

export const TextArchitecture1 = styled.div`
  font-size: 40px;
  font-weight: 500;
  text-transform: uppercase;
  padding-left: 20px;
  margin-top: -7px;
  line-height: 45px;
  color: #fff;

  @media (max-width: 1100px) {
    font-size: 32px;
    line-height: 37px;
  }

  @media (max-width: 950px) {
    line-height: 49px;
  }
`;

export const OutTextArchitecture = styled.div`
    height: 448px;
    overflow: hidden;
    overflow-y: scroll;
    padding-right: 30px;
    color: #000;
    padding-top: 20px;

    @media (max-width: 600px) {
      height: 304px;
   }
`;

export const TextArchitecture2 = styled.div`
  font-size: 20px;
  font-weight: 400;
  padding-left: 48px;
  color: #fff;
`;

export const RightArchitecture = styled.div`
  width: 64%;

  @media (max-width: 1350px) {
    width: 58%;
  }

  @media (max-width: 950px) {
    width: 100%;
    margin-top: 55px;
  }
`;
