import styled from 'styled-components';
import ImgBgCustomers from "../../Images/BgCustomers.jpg";

export const BgContact = styled.section`
    background-color: #fff;
    display: flex;
    justify-content: flex-end;
    padding-top: 10vw;
`;

export const OutContact = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @media (max-width: 1000px) {
        width: 100%;
    }
`;

export const TopContact = styled.div`
    display: flex;
    width: 95%;

    @media (max-width: 900px) {
        background-color: #e1e1e1;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 70px;
    }
`;

export const LeftTopContact = styled.div`
    display: flex;
    width: 30%;

    @media (max-width: 900px) {
        width: 224px;
    }
`;

export const RiskContact = styled.div`
    width: 27px;
`;

export const TextContact1 = styled.div`
    font-weight: 600;
    font-size: 40px;
    text-transform: uppercase;
    color: #000;
    padding-left: 30px;
    margin-top: -7px;

    @media (max-width: 900px) {
        padding-left: 16px;
        font-size: 33px;
        margin-top: -3px;
    }
`;

export const RightTopContact = styled.div`
    display: flex;
    width: 100%;

    @media (max-width: 900px) {
        width: 74%;
        display: none;
    }
`;

export const OutImgCinza = styled.div`
    width: 59px;

    @media (max-width: 900px) {
        display: none;
    }
`;

export const BgRightContact = styled.div`
    background-color: #e1e1e1;
    height: 66px;
    width: 96%;

    @media (max-width: 805px) {
        width: 74%;
    }
`;

export const ContainerContact = styled.div`
    display: flex;
    margin-top: -5px;
    margin-bottom: -5px;
    width: 100%;

    @media (max-width: 900px) {
        margin-top: 35px;
    }
`;

export const LeftContainer = styled.div`
    @media (max-width: 1250px) {
        width: 49%;
    }

    @media (max-width: 900px) {
        display: none;
    }
`;

export const OutImgLeftContainer = styled.div`
  @media (max-width: 1250px) {
    width: 100%;
  }  
  
`;

export const RightContainer = styled.div`
    width: 40%;
    padding-left: 4%;
    display: flex;
    align-items: center;

    @media (max-width: 900px) {
        width: 90%;
        padding-left: 0%;
        margin: 0 auto;
    }
`;

export const ButtonForm = styled.button`
    color: #fff;
    width: 240px;
    background-color: #000;
    border: 0px;
    margin-top: 20px;
    height: 40px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 17px;
    letter-spacing: 1px;
    cursor: pointer;
`;

export const OutImgContact2 = styled.div`
    width: 74.7%;

    @media (max-width: 900px) {
        width: 100%;
        margin-top: 35px;
    }    
    
    @media (max-width: 600px) {
        display: none;
    }
`;

export const OutImgContact2Resp = styled.div`
    display: none;

    @media (max-width: 600px) {
        display: block;
        margin-top: 35px;
    }
`;
