/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import * as S from "./estilos";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import useData from "../../Utils/useData";

import ImgLogo from "../../Images/Logo.png";
import ImgRiscoMenu from "../../Images/RiscoMenu.png";
import ImgIconLingua from "../../Images/IconLingua.png";
import ButtonFooter from "../../Images/ButtonFooter.png";
import IconFooter1 from "../../Images/IconFooter1.png";
import IconFooter2 from "../../Images/IconFooter2.png";
import IconFooter3 from "../../Images/IconFooter3.png";
import IconFooter4 from "../../Images/IconFooter4.png";

function Header(props: any) {
  const history = useHistory();
  const [menu, setMenu] = useState(0);
  const [data, setData] = useState<any>([]);
  const [load, setLoad] = useState(false);

  const MandaFace = () => {
    window.open("https://www.facebook.com/publistand.empreendimentos/?show_switched_toast=0&show_invite_to_follow=0&show_switched_tooltip=0&show_podcast_settings=0&show_community_review_changes=0&show_community_rollback=0&show_follower_visibility_disclosure=0", "_blank");
  };

  const MandaInsta = () => {
    window.open("https://www.instagram.com/publistand/", "_blank");
  };  
  
  const MandaLinkedin = () => {
    window.open("https://www.linkedin.com/in/ana-maria-oliveira-a3939770/", "_blank");
  };  
  
  const MandaYoutube = () => {
    window.open("https://www.youtube.com/channel/UCAgVkkVAuDyPoKunZIeJGCQ", "_blank");
  };

  const MandaPdf = () => {
    window.open("https://webaovivo.com.br/publistand/upload/download/Apresentação Publistand 2023 50A.pdf", "_blank");
  };

  const loadData = () => {
    useData()
      .getData("banner.php")
      .then((response: any) => {
        // alert(response);
        setData(response);
        console.log(response);
      })
      .catch((err) => {
        console.info(err);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  const MandaHome = () => {
    history.push("/Producao");
    setMenu(0);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 30,
    autoplay: true,
    autoplaySpeed: 2000,
    adaptiveHeight: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    const hash = props.history.location.hash;
    if (hash && document.getElementById(hash.substr(1))) {
      // Check if there is a hash and if an element with that id exists
      document.getElementById(hash.substr(1))?.scrollIntoView({ behavior: "smooth" });
    }
    // }, []);
  }, [props.history.location.hash]);

  return (
    <>
      <S.BgHeader>
        <S.OutHeader>
          <S.OutLogo>
            <img src={ImgLogo} />
          </S.OutLogo>
          <S.OutMenu>
            <S.ItemMenu>
              <S.OutRiscoMenu>
                <img src={ImgRiscoMenu} />
              </S.OutRiscoMenu>
              <S.TextItemMenu href='#Home'>Home</S.TextItemMenu>
            </S.ItemMenu>
            <S.ItemMenu>
              <S.OutRiscoMenu>
                <img src={ImgRiscoMenu} />
              </S.OutRiscoMenu>
              <S.TextItemMenu href='#Historic'>Histórico</S.TextItemMenu>
            </S.ItemMenu>
            <S.ItemMenu>
              <S.OutRiscoMenu>
                <img src={ImgRiscoMenu} />
              </S.OutRiscoMenu>
              <S.TextItemMenu href='#Cases'>Cases</S.TextItemMenu>
            </S.ItemMenu>
            <S.ItemMenu>
              <S.OutRiscoMenu>
                <img src={ImgRiscoMenu} />
              </S.OutRiscoMenu>
              <S.TextItemMenu href='#Arquitetura'>Arquitetura</S.TextItemMenu>
            </S.ItemMenu>
            <S.ItemMenu>
              <S.OutRiscoMenu>
                <img src={ImgRiscoMenu} />
              </S.OutRiscoMenu>
              <S.TextItemMenu href='#Client'>Clientes</S.TextItemMenu>
            </S.ItemMenu>
            <S.ItemMenu>
              <S.OutRiscoMenu>
                <img src={ImgRiscoMenu} />
              </S.OutRiscoMenu>
              <S.TextItemMenu href='#Contact'>Contato</S.TextItemMenu>
            </S.ItemMenu>
          </S.OutMenu>
          <S.RightHeader>
            <S.OutTelHeader>
              <S.TextTelHeader>
                55 11 <span>2776 9691</span>
              </S.TextTelHeader>
              <S.TextTelHeader>|</S.TextTelHeader>
              <S.TextTelHeader>
                <span>2776 9692</span>
              </S.TextTelHeader>
            </S.OutTelHeader>
            {/*
            <S.OutIconLingua>
              <img src={ImgIconLingua} />
            </S.OutIconLingua>
             */}
          </S.RightHeader>
          <S.OutButtonMobile onClick={() => setMenu(1)}>
            <S.RiskButtonMobile />
            <S.RiskButtonMobile />
            <S.RiskButtonMobile />
          </S.OutButtonMobile>
        </S.OutHeader>
      </S.BgHeader>

      {menu == 1 ? (
          <S.BgMenuMobile>
            <S.OutMenuMobile2>
              <S.OutLogoMobile><img src ={ImgLogo} /></S.OutLogoMobile>
              <S.TextXMobile onClick={() => setMenu(0)}>X</S.TextXMobile>
              <S.ContentMenuMobile>
                <S.TextMenuMobile1 href='#Home' onClick={() => setMenu(0)}>Home</S.TextMenuMobile1>
                <S.TextMenuMobile1 href='#Historic' onClick={() => setMenu(0)}>Histórico</S.TextMenuMobile1>
                <S.TextMenuMobile1 href='#Cases' onClick={() => setMenu(0)}>Cases</S.TextMenuMobile1>
                <S.TextMenuMobile1 href='#Arquitetura' onClick={() => setMenu(0)}>Arquitetura</S.TextMenuMobile1>
                <S.TextMenuMobile1 href='#Client' onClick={() => setMenu(0)}>Clientes</S.TextMenuMobile1>
                <S.TextMenuMobile1 href='#Contact' onClick={() => setMenu(0)}>Contato</S.TextMenuMobile1>
              </S.ContentMenuMobile>
              <S.OutIcons>
                <S.Icon onClick={() => MandaInsta()}>
                  <img src={IconFooter1} />
                </S.Icon>
                <S.Icon onClick={() => MandaFace()}>
                  <img src={IconFooter2} />
                </S.Icon>
                <S.Icon onClick={() => MandaYoutube()}>
                  <img src={IconFooter3} />
                </S.Icon>
                <S.Icon onClick={() => MandaLinkedin()}>
                  <img src={IconFooter4} />
                </S.Icon>
              </S.OutIcons>
              <S.OutButtonFooter onClick={() => MandaPdf()}>
                <img src={ButtonFooter} />
              </S.OutButtonFooter>
            </S.OutMenuMobile2>
          </S.BgMenuMobile>
        ) : (
         <></>
       )}

      <Slider  className="SliderBanner" {...settings}>
        {data &&
          data.map((data: any, index: React.Key | null | undefined) => (
            <S.OutImgBanner id='Home' key={index}>
              <img   src={`https://www.publistand.com.br/upload/banner/${data.id_tb_banner}a.${data.c_extensao1}`} />
            </S.OutImgBanner>
          ))}
      </Slider>
    </>
  );
}

export default withRouter(Header);
