import styled from "styled-components";
import ImgBgCustomers from "../../Images/BgCustomers.jpg";

export const BgCustomers = styled.section`
  margin-top: -20px;
  background-image: url(${ImgBgCustomers});
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 950px) {
    justify-content: center;
  }
`;

export const OutCustomers = styled.div`
  display: flex;
  width: 87%;
  padding-top: 7vw;
  padding-bottom: 7vw;

  @media (max-width: 1350px) {
    width: 95%;
    padding-top: 5vw;
    padding-bottom: 4vw;
  }

  @media (max-width: 950px) {
    width: 100%;
    flex-direction: column;
    padding-top: 9vw;
  }

  @media (max-width: 500px) {
    width: 100%;
    flex-direction: column;
    padding-top: 14vw;
  }
`;

export const LeftCustomers = styled.div`
  width: 31%;

  @media (max-width: 950px) {
    width: 85%;
    margin: 0 auto;
  }
`;

export const ContentCustomers = styled.div`
  display: flex;
`;

export const OutRisk = styled.div``;

export const TextCustomers1 = styled.div`
  font-size: 40px;
  font-weight: 500;
  text-transform: uppercase;
  padding-left: 20px;
  margin-top: -7px;
  line-height: 45px;
  color: #fff;
`;

export const TextCustomers2 = styled.div`
  font-size: 20px;
  font-weight: 400;
  padding-left: 48px;
  padding-top: 20px;
  color: #fff;

  @media (max-width: 1350px) {
    padding-left: 0px;
  }
`;

export const RightCustomers = styled.div`
  display: flex;
  width: 69%;

  @media (max-width: 1350px) {
    width: 100%;
    height: 395px;
  }

  @media (max-width: 950px) {
    margin-top: 50px;
    height: 330px;
  } 
  
  @media (max-width: 550px) {
    height: 225px;
  }
`;

export const OutImgBgCustomers = styled.div`
  width: 120px;
  margin-bottom: -5px;
`;

export const ContentRightCustomers = styled.div`
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const OutItensCustomers = styled.div`
  width: 650px;
  display: flex;
  flex-wrap: wrap;
  padding-left: 4%;
  justify-content: space-between;

  @media (max-width: 1350px) {
    width: 500px;
  }

  @media (max-width: 950px) {
    width: 86%;
    margin: 0 auto;
    padding-top: 0vw;
    padding-bottom: 0vw;
    padding-left: 0%;
  }
`;

export const TextClient = styled.div`
  color: #000;
  font-size: 20px;
  padding-top: 1vw;
  text-align: center;

  @media (max-width: 1350px) {
    font-size: 18px;
  }

  @media (max-width: 950px) {
    padding-top: 2vw;
  }
`;

export const itemCustomers = styled.div`
  width: 75% !important;
  display: flex;
  align-items: center;

  @media (max-width: 950px) {
    width: 100% !important;
  }
`;
