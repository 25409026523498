/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import * as S from "./estilos";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import useData from "../../Utils/useData";

import RiscoItem from "../../Images/RiscoItem.png";
import IconMais1 from "../../Images/IconMais1.png";
import IconMais2 from "../../Images/IconMais2.png";
import ImgBgCasesResp from "../../Images/BgCasesResp.jpg";
import ImgOpacity from "../../Images/ImgOpacity.png";

function Cases(props: any) {
  const [data, setData] = useState<any>([]);
  const [data2, setData2] = useState<any>([]);
  const [idFoto, setIdFoto] = useState(0);
  const [video, setVideo] = useState("");

  const loadData = () => {
    useData()
      .getData("cases.php")
      .then((response: any) => {
        setData(response);
      })
      .catch((err) => {
        console.info(err);
      });
  };

  const AbreVideos = (x: any) => {
    setVideo(x);
  };

  const AbrePop = (x: any) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      idTbShow: x,
    });

    fetch("https://www.publistand.com.br/app_site/fotos.php", {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    })
      .then((response) => response.text())
      .then((response: any) => {
        const array = JSON.parse(response);
        console.log(response);
        setData2(array);
        setIdFoto(x);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    loadData();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 100,
    autoplay: false,
    autoplaySpeed: 2000,
    adaptiveHeight: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <div id="Cases"></div>
      <S.OutCases>
        <Slider className="SliderCases1" {...settings}>
          {data &&
            data.map((data: any, index: React.Key | null | undefined) => (
              <S.ItemCases key={index}>
                <S.LeftCases>
                  <S.ContentLeftCases>
                    <S.TopLeftCases>
                      <S.OutImgRisk>
                        <img src={RiscoItem} />
                      </S.OutImgRisk>
                      <S.TextCases1>Cases</S.TextCases1>
                    </S.TopLeftCases>

                    <S.MidCases>
                      <S.TextCases2>{data.c_titulo}</S.TextCases2>
                      <S.TextCases3>
                        <span>Cliente: </span> {data.c_cliente}
                      </S.TextCases3>
                      <S.TextCases4>{data.c_texto}</S.TextCases4>
                      <S.RightCasesResp>
                        <S.OutIconsCases>
                          <S.IconCasesResp1 onClick={() => AbrePop(data.id_tb_show)}>
                            <img src={IconMais1} />
                          </S.IconCasesResp1>
                          {data.c_youtube !== "" && (
                            <S.IconCasesResp2 onClick={() => AbreVideos(data.c_youtube)}>
                              <img src={IconMais2} />
                            </S.IconCasesResp2>
                          )}
                        </S.OutIconsCases>
                      </S.RightCasesResp>
                    </S.MidCases>
                  </S.ContentLeftCases>
                </S.LeftCases>
                <S.RightCases
                  style={{
                    backgroundImage:
                      "url('https://www.publistand.com.br/upload/show/" + data.id_tb_foto + "." + data.c_extensao + "')",
                  }}>
                  <S.ImageOpacity>
                    <img src={ImgOpacity} />
                  </S.ImageOpacity>
                  <S.OutIconsCases>
                    <S.IconCases onClick={() => AbrePop(data.id_tb_show)}>
                      <img src={IconMais1} />
                    </S.IconCases>
                    {data.c_youtube !== "" && (
                      <S.IconCases onClick={() => AbreVideos(data.c_youtube)}>
                        <img src={IconMais2} />
                      </S.IconCases>
                    )}
                  </S.OutIconsCases>
                </S.RightCases>
              </S.ItemCases>
            ))}
        </Slider>
      </S.OutCases>

      {idFoto !== 0 && (
        <S.BgImageCases>
          <S.TextXCases onClick={() => setIdFoto(0)}>X</S.TextXCases>
          <Slider className="SliderImage" {...settings}>
            {data2 &&
              data2.map((data2: any, index2: React.Key | null | undefined) => (
                <S.OutImageCases key={index2}>
                  <img src={"https://www.publistand.com.br/upload/show/" + data2.id_tb_foto + "." + data2.c_extensao} />
                </S.OutImageCases>
              ))}
          </Slider>
        </S.BgImageCases>
      )}

      {video !== "" && (
        <S.BgImageCases>
          <S.TextXCases onClick={() => setVideo("")}>X</S.TextXCases>
          <iframe
            src={video}
            // src={"https://www.youtube.com/embed/ldNtfHakuQg"}
            width="100%"
            className="IframeEventos"
            frameBorder="0"
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          />
        </S.BgImageCases>
      )}
    </>
  );
}

export default withRouter(Cases);
