import styled from "styled-components";
// import ImgBgHeader from "../../Images/BgHeader.jpg";

export const BgHeader = styled.header`
  background-color: #000000;
  position: fixed;
  width: 100%;
  z-index: 100;
  height: 110px;
  display: flex;

  @media (max-width: 480px) {
    height: 90px;
  }
`;

export const OutHeader = styled.div`
  display: flex;
  width: 1315px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1360px) {
    width: 1030px;
  }

  @media (max-width: 1060px) {
    width: 880px;
  }

  @media (max-width: 920px) {
    width: 90%;
  }
`;

export const OutLogo = styled.div`
  cursor: pointer;

  @media (max-width: 1060px) {
    width: 92px;
  }
`;

export const OutMenu = styled.div`
  display: flex;
  width: 56%;
  justify-content: space-between;

  @media (max-width: 1060px) {
    width: 53%;
  }

  @media (max-width: 920px) {
    display: none;
  }
`;

export const ItemMenu = styled.div`
  display: flex;
  align-items: center;
`;

export const OutRiscoMenu = styled.div`
  display: none;
`;

export const TextItemMenu = styled.a`
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  color: #767676;
  cursor: pointer;
  text-decoration: none;

  :hover {
    color: #fff;
  }

  @media (max-width: 1060px) {
    font-size: 16px;
  }
`;

export const RightHeader = styled.div`
  display: flex;
  width: 24%;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1360px) {
    width: 29%;
  }

  @media (max-width: 920px) {
    width: 255px;
  }

  @media (max-width: 480px) {
    display: none;
  }
`;

export const OutTelHeader = styled.div`
  display: flex;
`;

export const TextTelHeader = styled.div`
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  color: #767676;
  cursor: pointer;

  span {
    color: #fff;
  }

  @media (max-width: 1060px) {
    font-size: 15px;
  }
`;

export const OutIconLingua = styled.div`
  cursor: pointer;
`;

export const OutImgBanner = styled.div`
  cursor: pointer;
  margin-bottom: -5px;
  // padding-top: 110px;
`;

export const OutButtonMobile = styled.div`
  display: none;
  cursor: pointer;

  @media (max-width: 920px) {
    display: flex;
    flex-direction: column;
    height: 29px;
    justify-content: space-between;
  }
`;

export const RiskButtonMobile = styled.div`
  width: 40px;
  height: 5px;
  background-color: #fff;
  border-radius: 1px;
`;

export const BgMenuMobile = styled.div`
  background-color: #000;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  top: 0px;
  left: 0px;
`;

export const OutMenuMobile2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const OutLogoMobile = styled.div`
  width: 115px;
  margin-top: 18px;
`;

export const TextXMobile = styled.div`
  font-weight: bold;
  position: absolute;
  right: 35px;
  font-size: 30px;
  top: 15px;
  cursor: pointer;
  color: #ffff;
`;

export const ContentMenuMobile = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
`;

export const TextMenuMobile1 = styled.a`
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
  border-bottom: 1px solid;
  text-align: center;
  padding-bottom: 15px;
  padding-top: 15px;
  color: #fff;
  text-decoration: none;
`;

export const OutIcons = styled.div`
  display: flex;
  width: 190px;
  margin-top: 30px;
  justify-content: space-between;
`;

export const Icon = styled.div`
cursor: pointer;
`;

export const OutButtonFooter = styled.div`
  width: 190px;
  margin-top: 20px;
  cursor: pointer;
`;
