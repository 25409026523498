/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import * as S from "./estilos";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import useData from "../../Utils/useData";

import RiscoMenu2 from "../../Images/RiscoMenu2.png";
import ImgContentCustomers from "../../Images/ImgContentCustomers.png";
import ImgItemCustomers1 from "../../Images/ImgItemCustomers1.png";
import ImgItemCustomers2 from "../../Images/ImgItemCustomers2.png";

function Customers(props: any) {
  const [data, setData] = useState<any>([]);
  const [load, setLoad] = useState(false);

  const loadData = () => {
    useData()
      .getData("clientes.php")
      .then((response: any) => {
        // alert(response);
        setData(response);
      })
      .catch((err) => {
        console.info(err);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 100,
    autoplay: false,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 3,
    rows: 5,

    responsive: [
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          rows: 3,
        },
      },
    ],
  };

  return (
    <>
      <div id='Client'></div>
      <S.BgCustomers>
        <S.OutCustomers>
          <S.LeftCustomers>
            <S.ContentCustomers>
              <S.OutRisk>
                <img src={RiscoMenu2} />
              </S.OutRisk>
              <S.TextCustomers1>Clientes Atendidos</S.TextCustomers1>
            </S.ContentCustomers>
            <S.TextCustomers2>
              Segmento altamente competitivo as feiras e exposições têm assegurado um lugar proeminente a Publistand,
              devido sua eficiência e comportamento ético. O expositor que considera essencial alcançar os resultados
              máximos em todas as suas realizações atesta a competência dos serviços prestados pela Publistand.
            </S.TextCustomers2>
          </S.LeftCustomers>
          <S.RightCustomers>
            <S.OutImgBgCustomers>
              <img src={ImgContentCustomers} />
            </S.OutImgBgCustomers>
            <S.ContentRightCustomers>
              <S.OutItensCustomers>
                <Slider className="SliderClientes" {...settings}>
                  {data &&
                    data.map((data: any, index: React.Key | null | undefined) => (
                      <S.TextClient key={index}>{data.c_titulo}</S.TextClient>
                    ))}
                </Slider>
              </S.OutItensCustomers>
            </S.ContentRightCustomers>
          </S.RightCustomers>
        </S.OutCustomers>
      </S.BgCustomers>
    </>
  );
}

export default withRouter(Customers);
