const requestHeaders = new Headers();
requestHeaders.set('Content-Type', 'application/json');
const baseUrl = process.env.REACT_APP_ENDPOINT;

export default function useData() {
  const getData = function (url: string) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async function (resolve, reject) {
      // console.log(`${baseUrl}${url}`);

      await fetch(`${baseUrl}${url}`, {
        method: 'GET',
        redirect: 'follow',
        // crossDomain: true,
        headers: {
          // Accept: 'application/json',
          // 'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          resolve(responseJson);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const postData = function (url: string, data: any) {
    // console.log(data);

    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async function (resolve, reject) {
      await fetch(`${baseUrl}${url}`, {
        method: 'POST',
        // crossDomain: true,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          resolve(responseJson);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  return {
    getData,
    postData,
  };
}
